/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Block from 'components/elements/Blocks/BlockDefault'
import Share from 'components/elements/Misc/Share'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & blockquote {
    border-left: 2px solid ${({ theme }) => theme.color.secondary};
    padding-left: 2rem;
  }

  @media (max-width: 991px) {
    & h1,
    & h2 {
      font-size: 25px;
      margin-bottom: 0;
      margin-top: 2rem;
      line-height: 25px;
    }

    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 20px;
      margin-bottom: 0;
      margin-top: 2rem;
      line-height: 20px;
    }
  }

  @media (min-width: 992px) {
    & h1,
    & h2 {
      font-size: 30px;
      margin-bottom: 0;
      margin-top: 2rem;
      line-height: 30px;
    }

    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 25px;
      margin-bottom: 0;
      margin-top: 2rem;
      line-height: 25px;
    }
  }
`

const Main = styled.article`
  color: ${({ theme }) => theme.color.primary};
`

const TextBlog = ({ fields, location }: TextProps) => (
  <section style={{ minHeight: '70vh' }}>
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-lg-1">
          {location.pathname !== `/privacyverklaring/` && (
            <Share
              heading="Deel dit artikel"
              location={location}
              title="Artikel van GTS Online"
              body={fields.case?.intro || ''}
              className="d-flex flex-row flex-lg-column align-items-center mt-5 pt-5 mb-5 mb-lg-0"
            />
          )}
        </div>
        <div className="col-lg-9">
          <Block grey sharp className="py-5 px-sm-5 overflow-hidden">
            <Main className="px-lg-5">
              <Content content={fields?.description} className="pb-5" />
              {fields.blog?.images &&
                fields.blog?.images?.map((image, index) => (
                  <React.Fragment key={index}>
                    <ParseContent
                      content={image?.image?.description}
                      className="my-4"
                    />
                    <Plaatjie image={image?.image} alt="" className="mb-4" />
                  </React.Fragment>
                ))}
            </Main>
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default TextBlog
